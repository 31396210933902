import Vue from 'vue';
import Vuelidate from 'vuelidate';
import BootstrapVue from 'bootstrap-vue';
import MetronicVue from '@vedicium/metronic-vue';
import core from '@vedicium/core-vue';
import moment from 'moment-timezone';
import router from '@/router/index';
import vueI18n from '@/i18n/index';
import companyModule from '@vedicium/core-vue/build/modules/company';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';

import User from '@/libs/classes/user';

import App from '@/App.vue';

// Vue Libraries
Vue.use(MetronicVue);
Vue.use(Vuelidate);
Vue.use(BootstrapVue);
Vue.use(core, {
  websocket: {
    uri: (process.env.VUE_APP_WS_URI || 'http://localhost:3000'),
    options: {
      autoConnect: true,
    },
  },
  http: {
    uri: (process.env.VUE_APP_HTTP_URI || 'http://localhost:3000'),
  },
  permissions: {
    enabled: true,
  },
});
library.add(fas);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);

Vue.use(companyModule, {
  uri: '/v2/companies',
});

// Overwrite types
Vue.prototype.$moment = moment;

const init = async () => {
  // Listen on auth changes
  core.eventhub.on('core:auth:authenticated', (authenticated) => {
    if (authenticated && core.auth.resource) {
      // Set resource as user
      if (core.auth.isResourceFromIndex('users')) {
        core.auth.resource = new User().merge(core.auth.resource);
      }
    }
  });

  // Wait for core to initialize
  await core.init();

  return new Vue({
    el: '#app',
    router,
    i18n: vueI18n,
    components: { App },
    template: '<App />',
  });
};

init();
