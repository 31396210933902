export default {
  critical_errors: {
    title: 'Critical errors',
    description: 'To notify you if a critical error occured on your sunbed.',
  },
  'non-critical_errors': {
    title: 'Non-critical errors',
    description: 'To notify you if a non-critical error occured on your sunbed.',
  },
  'maintenance:preventative': {
    title: 'Maintenance - Preventative',
    description: 'To notify you before your sunbed requires maintenance.',
  },
  'maintenance:due': {
    title: 'Maintenance - Due',
    description: 'To notify you if your sunbed requires maintenance.',
  },

  triggers: {
    maintenance: {
      counters: {
        lamps: {
          title: 'Lamps',
          description: 'Available in Luxura Jewel, Vegaz, X7, X5, V8 & V6',
        },
        ft: {
          title: 'Face tanner',
          description: 'Available in Luxura Jewel, Vegaz, X7, X5',
        },
        st: {
          title: 'Shoulder tanner',
          description: 'Available in Luxura Vegaz',
        },
        stExt: {
          title: 'Shoulder tanner extension',
          description: 'Available in Luxura Jewel',
        },
        stBench: {
          title: 'Shoulder tanner bench',
          description: 'Available in Luxura Jewel',
        },
        bb: {
          title: 'Boss-Booster UVA',
          description: 'Available in Luxura Jewel',
        },
        ledUva: {
          title: 'UVA LED',
          description: 'Available in Luxura Vegaz',
        },
        ledRed: {
          title: 'RED LED',
          description: 'Available in Luxura Vegaz',
        },
        acrylicBench: {
          title: 'Acrylic bench',
          description: 'Available in Luxura Jewel, Vegaz',
        },
        acrylicAirco: {
          title: 'Acrylic climate control',
          description: 'Available in Luxura Jewel, Vegaz',
        },
        rearCasing: {
          title: 'Acrylic back panel',
          description: 'Available in Luxura Jewel, Vegaz',
        },
        acrylicCanopy: {
          title: 'Acrylic canopy',
          description: 'Available in Luxura Jewel, Vegaz',
        },
        airco: {
          title: 'Climate Control / Air conditioning',
          description: 'Available in Luxura Jewel, Vegaz, X7, X5',
        },
      },
    },
  },
};
