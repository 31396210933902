export const AMBIENT_COLORS = [
  { name: 'Black', color: '#000000' },
  { name: 'Red', color: '#ff0000' },
  { name: 'Full orange', color: '#FF8C00' },
  { name: 'Orange', color: '#FFA500' },
  { name: 'Yellow orange', color: '#FFAE42' },
  { name: 'Yellow', color: '#FF0' },
  { name: 'Fresh yellow', color: '#F7E190' },
  { name: 'Fresh green', color: '#00FFAA' },
  { name: 'White green', color: '#A0F9A0' },
  { name: 'Light green', color: '#90EE90' },
  { name: 'Bright green', color: '#66FF00' },
  { name: 'Green', color: '#00FF00' },
  { name: 'Sea green', color: '#2E8B57' },
  { name: 'Bright sea green', color: '#05ffa6' },
  { name: 'Bright blue green', color: '#0D98BA' },
  { name: 'Ice blue', color: '#BAF2EF' },
  { name: 'Light blue', color: '#ADD8E6' },
  { name: 'Intense blue', color: '#00ccff' },
  { name: 'Bright blue', color: '#0066ff' },
  { name: 'Nearly full blue', color: '#0033ff' },
  { name: 'Full blue', color: '#0000FF' },
  { name: 'Soft purple', color: '#3300cc' },
  { name: 'Intense purple', color: '#6600ff' },
  { name: 'Bright purple', color: '#b300ff' },
  { name: 'Dark pink', color: '#E75480' },
  { name: 'Weak pink', color: '#ff8080' },
  { name: 'Light pink', color: '#ff3366' },
  { name: 'Purple pink', color: '#D982B5' },
  { name: 'Pink', color: '#FFC0CB' },
  { name: 'Dark pink', color: '#ff001a' },
  { name: 'Dark red', color: '#B30000' },
  { name: 'White', color: '#FFF' },
];

export const AMBIENT_EFFECTS = [
  {
    name: 'Solid color',
    colors: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
  },
  {
    name: 'All colors',
    timePerColor: 5,
    fadeTime: 1,
    colors: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
  },
  {
    name: 'Base colors quickly',
    timePerColor: 10,
    fadeTime: 2,
    colors: [1, 7, 11, 16, 20, 24],
  },
  {
    name: 'Base colors slowly',
    timePerColor: 10,
    fadeTime: 10,
    colors: [1, 11, 20],
  },
  {
    name: 'Shades of white',
    timePerColor: 10,
    fadeTime: 2,
    colors: [8, 15, 26, 31],
  },
  {
    name: 'Soft colors',
    timePerColor: 10,
    fadeTime: 2,
    colors: [4, 14, 21, 26],
  },
  {
    name: 'Shades of white (base white)',
    timePerColor: 10,
    fadeTime: 2,
    colors: [6, 31, 13, 31, 15, 31, 21, 31, 26, 31],
  },
  {
    name: 'Shades of red, orange and yellow',
    timePerColor: 10,
    fadeTime: 2,
    colors: [30, 1, 2, 3, 4, 5, 6, 5, 4, 3, 2, 1],
  },
  {
    name: 'Shades of purple and pink',
    timePerColor: 5,
    fadeTime: 1,
    colors: [21, 22, 23, 24, 25, 27, 28, 29, 28, 27, 25, 24, 23, 22],
  },
  {
    name: 'Shades of red',
    timePerColor: 10,
    fadeTime: 5,
    colors: [30, 1, 2, 1],
  },
  {
    name: 'Shades of orange',
    timePerColor: 10,
    fadeTime: 10,
    colors: [1, 20],
  },
  {
    name: 'Shades of yellow',
    timePerColor: 5,
    fadeTime: 20,
    colors: [4, 5, 6, 5],
  },
  {
    name: 'Shades of green',
    timePerColor: 5,
    fadeTime: 2,
    colors: [9, 10, 11, 12, 13, 12, 11, 10, 9],
  },
  {
    name: 'Shades of blue',
    timePerColor: 5,
    fadeTime: 2,
    colors: [15, 16, 17, 18, 19, 20, 19, 18, 17, 16],
  },
  {
    name: 'Shades of purple',
    timePerColor: 5,
    fadeTime: 2,
    colors: [21, 22, 23, 27, 23, 22],
  },
  {
    name: 'Shades of pink',
    timePerColor: 5,
    fadeTime: 2,
    colors: [24, 25, 27, 28, 29, 28, 27, 25],
  },
];
