export default {
  legacy_device_warning: 'MyLuxura 2.0 currently only supports these features for the MyLuxura Module (MLM).<br />Please contact <a href="mailto:support@vdlhapro.com">support@vdlhapro.com</a> in order to request an upgrade-kit, or visit <a href="https://legacy.myluxura.com/" target="_blank">https://legacy.myluxura.com/</a> to use legacy features.',

  states: {
    offline: {
      title: 'Offline',
      description: 'Sunbed is not connected to (wireless) internet',
    },
    'no-device': {
      title: 'Unlinked',
      description: 'Sunbed is not linked to the corresponding MyLuxura module',
    },

    'controller-timeout': {
      title: 'Disconnected',
      description: 'Sunbed is disconnected from MyLuxura module',
    },
    'controller-powerup': {
      title: 'Power up',
      description: 'Sunbed is powering up',
    },
    'controller-pincode': {
      title: 'PIN required',
      description: 'Sunbed requires PIN in order to proceed',
    },
    'controller-config-view': {
      title: 'Parameters',
      description: 'Sunbed\'s parameter menu is being accessed',
    },
    'controller-quickstatus-view': {
      title: 'Quickstatus',
      description: 'Sunbed\'s quickstatus menu is being accessed',
    },
    'controller-setting-time': {
      title: 'Session configuration',
      description: 'Sunbed is awaiting session time configuration',
    },
    'controller-warning': {
      title: 'User alert',
      description: 'Sunbed is displaying pre-session safety alert(s)',
    },
    'controller-error': {
      title: 'Error',
      description: 'Sunbed is currently in error',
    },
    'controller-confirm': {
      title: 'Confirmation',
      description: 'Confirmation of quickstatus change',
    },
    'controller-copy-files': {
      title: 'Updating',
      description: 'Sunbed\'s currently updating its software (USB)',
    },

    'controller-online-update': {
      title: 'Updating',
      description: 'Sunbed\'s currently updating its software (MyLuxura)',
    },
    'controller-online-parameter-change': {
      title: 'Parameter update',
      description: 'Sunbed\'s downloading and configuring parameters',
    },

    'stand-by': {
      title: 'Available',
      description: 'Sunbed is ready to start a session',
    },
    'in-session': {
      title: 'In session',
      description: 'Sunbed is currently in session',
    },
    cooldown: {
      title: 'Cooldown',
      description: 'Sunbed is cooling down from session',
    },
    'session-paused': {
      title: 'Paused',
      description: 'Sunbed\'s session is being paused',
    },
    cleaning: {
      title: 'Cleaning',
      description: 'Sunbed\'s currently in cleaning mode',
    },
  },

  models: {
    // Luxura Jewel
    luxura_jewel: {
      name: 'Luxura Jewel',
      types: {
        EQ45: 'EQ45',
        EQ55: 'EQ55',
      },
      temperatures: {
        ntc1: {
          name: 'Lamps canopy',
          description: 'Excluding facial tanners.',
        },
        ntc2: {
          name: 'Lamps bench',
          description: 'Excluding optional shoulder tanners.',
        },
        ntc3: {
          name: 'Room temperature',
          description: '0,5m above floor',
        },
        ntc4: {
          name: 'Room temperature',
          description: '1,5m above floor',
        },
      },
      maintenance: {
        hours: {
          lamps: {
            name: 'Lamps',
          },
          ft: {
            name: 'Face tanner',
          },
          stExt: {
            name: 'Shoulder tanner extension',
          },
          stBench: {
            name: 'Shoulder tanner bench',
          },
          bb: {
            name: 'Boss-Booster UVA',
          },
          airco: {
            name: 'Climate control',
          },
          acrylicBench: {
            name: 'Acrylic bench',
          },
          acrylicAirco: {
            name: 'Acrylic climate control',
          },
          rearCasing: {
            name: 'Acrylic back panel',
          },
          acrylicCanopy: {
            name: 'Acrylic canopy',
          },
        },
      },
    },

    // Luxura Vegaz
    luxura_vegaz_9200: {
      name: 'Luxura Vegaz 9200',
      types: {
        '9200_balance': '9200 Balance',
        '9200_intelligent': '9200 Intelligent',
        '9200_highbrid': '9200 Highbrid',
      },
      filters: {
        BPS: 'Filterglass BPS',
        IPS: 'Filterglass IPS',
        HPS: 'Filterglass HPS',
        Highbrid: 'Filterglass Highbrid',
      },
      temperatures: {
        ntc1: {
          name: 'Lamps canopy',
          description: 'Excluding facial tanners.',
        },
        ntc2: {
          name: 'Lamps bench',
          description: 'Excluding optional shoulder tanners.',
        },
        ntc3: {
          name: 'Room temperature',
          description: '0,5m above floor',
        },
        ntc4: {
          name: 'Room temperature',
          description: '1,5m above floor',
        },
      },
      maintenance: {
        hours: {
          lamps: {
            name: 'Lamps',
          },
          ft: {
            name: 'Face tanner',
          },
          st: {
            name: 'Shoulder tanner',
          },
          ledUva: {
            name: 'UVA LED',
          },
          ledRed: {
            name: 'Red LED',
          },
          acrylicBench: {
            name: 'Acrylic bench',
          },
          acrylicAirco: {
            name: 'Acrylic climate control',
          },
          rearCasing: {
            name: 'Acrylic back panel',
          },
          acrylicCanopy: {
            name: 'Acrylic canopy',
          },
          airco: {
            name: 'Climate control',
          },
        },
      },
    },
    luxura_vegaz_8200: {
      name: 'Luxura Vegaz 8200',
      types: {
        '8200_balance': '8200 Balance',
        '8200_intelligent': '8200 Intelligent',
        '8200_highbrid': '8200 Highbrid',
      },
      filters: {
        BPS: 'Filterglass BPS',
        IPS: 'Filterglass IPS',
        HPS: 'Filterglass HPS',
        Highbrid: 'Filterglass Highbrid',
      },
      temperatures: {
        ntc1: {
          name: 'Lamps canopy',
        },
        ntc2: {
          name: 'Lamps bench',
        },
        ntc3: {
          name: 'Room temperature',
          description: '0,5m above floor',
        },
        ntc4: {
          name: 'Room temperature',
          description: '1,5m above floor',
        },
      },
      maintenance: {
        hours: {
          lamps: {
            name: 'Lamps',
          },
          ft: {
            name: 'Face tanner',
          },
          st: {
            name: 'Shoulder tanner',
          },
          ledUva: {
            name: 'LED UVA',
          },
          ledRed: {
            name: 'LED Red',
          },
          acrylicBench: {
            name: 'Acrylic bench',
          },
          acrylicAirco: {
            name: 'Acrylic climate control',
          },
          rearCasing: {
            name: 'Acrylic back panel',
          },
          acrylicCanopy: {
            name: 'Acrylic canopy',
          },
          airco: {
            name: 'Climate control',
          },
        },
      },
    },

    // Luxura X7
    luxura_x7: {
      name: 'Luxura X7',
      types: {
        '38_sli': '38 SLi',
        '38_sli_intensive': '38 SLi Intensive',
        '38_sli_high_intensive': '38 SLi High Intensive',
        '38_sli_balance': '38 SLi Balance',
        '38_sli_intelligent': '38 SLi Intelligent',
        '38_highbrid': '38 Highbrid',
        '42_sli': '42 SLi',
        '42_sli_intensive': '42 SLi Intensive',
        '42_sli_high_intensive': '42 SLi High Intensive',
        '42_sli_balance': '42 SLi Balance',
        '42_sli_intelligent': '42 SLi Intelligent',
        '42_highbrid': '42 Highbrid',
      },
      filters: {
        BPS: 'Filterglass BPS',
        HPS: 'Filterglass HPS',
        Highbrid: 'Filterglass Highbrid',
      },
      temperatures: {
        ntc1: {
          name: 'Lamps canopy',
        },
        ntc2: {
          name: 'Room temperature',
          description: '1,5m above floor',
        },
        ntc3: {
          name: 'Room temperature',
          description: '0,5m above floor',
        },
        ntc4: {
          name: 'Lamps bench',
        },
      },
      maintenance: {
        hours: {
          lamps: {
            name: 'Lamps',
          },
          ft: {
            name: 'Face Tanner',
          },
          acrylicBench: {
            name: 'Acrylic bench',
          },
          acrylicAirco: {
            name: 'Acrylic climax',
          },
          acrylicCanopy: {
            name: 'Acrylic canopy',
          },
          airco: {
            name: 'Air conditioning',
          },
          xsens1: {
            name: 'Xsens 1',
          },
          xsens2: {
            name: 'Xsens 2',
          },
          xsens3: {
            name: 'Xsens 3',
          },
        },
      },
    },

    // Luxura X5
    luxura_x5: {
      name: 'Luxura X5',
      types: {
        '34_sli': '34 SLi',
        '34_sli_intensive': '34 SLi Intensive',
        '34_sli_high_intensive': '34 SLi High Intensive',
        '34_sli_balance': '34 SLi Balance',
        '38_sli_high_intensive': '38 SLi High Intensive',
        C700_seecret: 'C700 Seecret',
      },
      filters: {
        BPS: 'Filterglass BPS',
        HPS: 'Filterglass HPS',
        Highbrid: 'Filterglass Highbrid',
      },
      temperatures: {
        ntc1: {
          name: 'Lamps canopy',
        },
        ntc2: {
          name: 'Room temperature (1,5m above floor)',
        },
        ntc3: {
          name: 'Room temperature (0,5m above floor)',
        },
        ntc4: {
          name: 'Lamps bench',
        },
      },
      maintenance: {
        hours: {
          lamps: {
            name: 'Lamps',
          },
          ft: {
            name: 'Face Tanner',
          },
          acrylicBench: {
            name: 'Acrylic bench',
          },
          acrylicAirco: {
            name: 'Acrylic climax',
          },
          acrylicCanopy: {
            name: 'Acrylic canopy',
          },
          airco: {
            name: 'Air conditioning',
          },
          xsens1: {
            name: 'Xsens 1',
          },
          xsens2: {
            name: 'Xsens 2',
          },
          xsens3: {
            name: 'Xsens 3',
          },
        },
      },
    },

    // Luxura V6
    luxura_v6: {
      name: 'Luxura V6',
      types: {
        '42_xl': '42 XL',
        '44xl_balance': '44XL Balance',
        '44xl_balance_ultra': '44XL Balance Ultra',
        '48xl_balance': '48XL Balance',
        '48xl_balance_ultra': '48XL Balance Ultra',
      },
      maintenance: {
        hours: {
          lamps: {
            name: 'Lamps',
          },
        },
      },
    },

    // Luxura V8
    luxura_v8: {
      name: 'Luxura V8',
      types: {
        '48xl_balance': '48XL Balance',
        '48xl_balance_ultra': '48XL Balance Ultra',
        '48xl_intelligent': '48XL Intelligent',
        '48xl_highbrid': '48XL Highbrid',
      },
      maintenance: {
        hours: {
          lamps: {
            name: 'Lamps',
          },
        },
      },
    },
  },
};
