<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
};
</script>

<style lang="scss">
// Add styling
@import "@/assets/sass/init.scss";
</style>
