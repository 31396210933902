import Vue from 'vue';
import Router from 'vue-router';
import core, { router as routerLib } from '@vedicium/core-vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      component: () => import('@/components/layout/index.vue'),
      children: [
        {
          path: '/dashboard',
          name: '/dashboard',
          component: () => import('@/components/pages/dashboard/index.vue'),
        },

        // Sunbeds
        {
          path: '/sunbeds',
          name: '/sunbeds',
          redirect: '/sunbeds/overview',
        },
        {
          path: '/sunbeds/overview',
          name: '/sunbeds/overview',
          component: () => import('@/components/pages/sunbeds/overview.vue'),
        },
        {
          path: '/sunbeds/view/:guid',
          name: '/sunbeds/view/:guid',
          redirect: '/sunbeds/view/:guid/general',
          component: () => import('@/components/pages/sunbeds/view/index.vue'),
          children: [
            // General
            {
              path: '/sunbeds/view/:guid/general',
              name: '/sunbeds/view/:guid/general',
              component: () => import('@/components/pages/sunbeds/view/pages/general/index.vue'),
              redirect: '/sunbeds/view/:guid/general/status',
              children: [
                {
                  path: '/sunbeds/view/:guid/general/status',
                  name: '/sunbeds/view/:guid/general/status',
                  component: () => import('@/components/pages/sunbeds/view/pages/general/status.vue'),
                },

                {
                  path: '/sunbeds/view/:guid/general/information',
                  name: '/sunbeds/view/:guid/general/information',
                  component: () => import('@/components/pages/sunbeds/view/pages/general/information.vue'),
                },
                {
                  path: '/sunbeds/view/:guid/general/remarks',
                  name: '/sunbeds/view/:guid/general/remarks',
                  component: () => import('@/components/pages/sunbeds/view/pages/general/remarks/index.vue'),
                },
              ],
            },

            // Health
            {
              path: '/sunbeds/view/:guid/health',
              name: '/sunbeds/view/:guid/health',
              component: () => import('@/components/pages/sunbeds/view/pages/health/index.vue'),
              redirect: '/sunbeds/view/:guid/health/maintenance',
              children: [
                {
                  path: '/sunbeds/view/:guid/health/maintenance',
                  name: '/sunbeds/view/:guid/health/maintenance',
                  component: () => import('@/components/pages/sunbeds/view/pages/health/maintenance.vue'),
                },
                {
                  path: '/sunbeds/view/:guid/health/temperatures',
                  name: '/sunbeds/view/:guid/health/temperatures',
                  component: () => import('@/components/pages/sunbeds/view/pages/health/temperatures/index.vue'),
                },
                {
                  path: '/sunbeds/view/:guid/health/modules',
                  name: '/sunbeds/view/:guid/health/modules',
                  component: () => import('@/components/pages/sunbeds/view/pages/health/modules.vue'),
                },
                {
                  path: '/sunbeds/view/:guid/health/errors',
                  name: '/sunbeds/view/:guid/health/errors',
                  component: () => import('@/components/pages/sunbeds/view/pages/health/errors/index.vue'),
                },
              ],
            },

            // Updates
            {
              path: '/sunbeds/view/:guid/updates',
              name: '/sunbeds/view/:guid/updates',
              redirect: '/sunbeds/view/:guid/updates/sunbed',
              component: () => import('@/components/pages/sunbeds/view/pages/updates/index.vue'),
              children: [
                // {
                //   path: '/sunbeds/view/:guid/updates/overview',
                //   name: '/sunbeds/view/:guid/updates/overview',
                //   component: () => import('@/components/pages/sunbeds/view/pages/updates/overview.vue'),
                // },

                {
                  path: '/sunbeds/view/:guid/updates/sunbed',
                  name: '/sunbeds/view/:guid/updates/sunbed',
                  component: () => import('@/components/pages/sunbeds/view/pages/updates/sunbed.vue'),
                },
              ],
            },

            // Statistics
            {
              path: '/sunbeds/view/:guid/statistics',
              name: '/sunbeds/view/:guid/statistics',
              redirect: '/sunbeds/view/:guid/statistics/overall',
              component: () => import('@/components/pages/sunbeds/view/pages/statistics/index.vue'),
              children: [
                {
                  path: '/sunbeds/view/:guid/statistics/overall',
                  name: '/sunbeds/view/:guid/statistics/overall',
                  component: () => import('@/components/pages/sunbeds/view/pages/statistics/overall/index.vue'),
                },

                {
                  path: '/sunbeds/view/:guid/statistics/performance',
                  name: '/sunbeds/view/:guid/statistics/performance',
                  component: () => import('@/components/pages/sunbeds/view/pages/statistics/performance/index.vue'),
                },
                {
                  path: '/sunbeds/view/:guid/statistics/sessions',
                  name: '/sunbeds/view/:guid/statistics/sessions',
                  component: () => import('@/components/pages/sunbeds/view/pages/statistics/sessions.vue'),
                },
                {
                  path: '/sunbeds/view/:guid/statistics/peak-hours',
                  name: '/sunbeds/view/:guid/statistics/peak-hours',
                  component: () => import('@/components/pages/sunbeds/view/pages/statistics/peak-hours.vue'),
                },

                {
                  path: '/sunbeds/view/:guid/statistics/key-figures',
                  name: '/sunbeds/view/:guid/statistics/key-figures',
                  component: () => import('@/components/pages/sunbeds/view/pages/statistics/key-figures.vue'),
                },
              ],
            },

            // Settings
            {
              path: '/sunbeds/view/:guid/settings',
              name: '/sunbeds/view/:guid/settings',
              redirect: '/sunbeds/view/:guid/settings/sunbed',
              component: () => import('@/components/pages/sunbeds/view/pages/settings/index.vue'),
              children: [
                {
                  path: '/sunbeds/view/:guid/settings/sunbed',
                  name: '/sunbeds/view/:guid/settings/sunbed',
                  component: () => import('@/components/pages/sunbeds/view/pages/settings/sunbed/index.vue'),
                },
                {
                  path: '/sunbeds/view/:guid/settings/radio',
                  name: '/sunbeds/view/:guid/settings/radio',
                  component: () => import('@/components/pages/sunbeds/view/pages/settings/radio.vue'),
                },
                {
                  path: '/sunbeds/view/:guid/settings/management',
                  name: '/sunbeds/view/:guid/settings/management',
                  component: () => import('@/components/pages/sunbeds/view/pages/settings/management.vue'),
                },
                {
                  path: '/sunbeds/view/:guid/settings/link-company',
                  name: '/sunbeds/view/:guid/settings/link-company',
                  component: () => import('@/components/pages/sunbeds/view/pages/settings/link-company.vue'),
                },

                {
                  path: '/sunbeds/view/:guid/settings/module',
                  name: '/sunbeds/view/:guid/settings/module',
                  component: () => import('@/components/pages/sunbeds/view/pages/settings/module.vue'),
                },

                {
                  path: '/sunbeds/view/:guid/settings/options',
                  name: '/sunbeds/view/:guid/settings/options',
                  component: () => import('@/components/pages/sunbeds/view/pages/settings/options.vue'),
                },
              ],
            },

            // Development
            {
              path: '/sunbeds/view/:guid/development',
              name: '/sunbeds/view/:guid/development',
              redirect: '/sunbeds/view/:guid/development/controller',
              component: () => import('@/components/pages/sunbeds/view/pages/development/index.vue'),
              children: [
                {
                  path: '/sunbeds/view/:guid/development/controller',
                  name: '/sunbeds/view/:guid/development/controller',
                  component: () => import('@/components/pages/sunbeds/view/pages/development/controller.vue'),
                },
                {
                  path: '/sunbeds/view/:guid/development/module',
                  name: '/sunbeds/view/:guid/development/module',
                  component: () => import('@/components/pages/sunbeds/view/pages/development/module.vue'),
                },
                {
                  path: '/sunbeds/view/:guid/development/logs',
                  name: '/sunbeds/view/:guid/development/logs',
                  component: () => import('@/components/pages/sunbeds/view/pages/development/logs.vue'),
                },
                {
                  path: '/sunbeds/view/:guid/development/export-logs',
                  name: '/sunbeds/view/:guid/development/export-logs',
                  component: () => import('@/components/pages/sunbeds/view/pages/development/export-logs.vue'),
                },
              ],
            },
          ],
        },
        {
          path: '/sunbeds/create',
          name: '/sunbeds/create',
          component: () => import('@/components/pages/sunbeds/create.vue'),
        },

        // Overall statistics
        {
          path: '/statistics/sunbeds/errors',
          name: '/statistics/sunbeds/errors',
          component: () => import('@/components/pages/statistics/sunbeds/errors/index.vue'),
        },

        // Notifications
        {
          path: '/notifications',
          name: '/notifications',
          component: () => import('@/components/pages/notifications/index.vue'),
          redirect: '/notifications/settings',
          children: [
            // Settings
            {
              path: '/notifications/settings',
              name: '/notifications/settings',
              component: () => import('@/components/pages/notifications/settings/index.vue'),
              redirect: '/notifications/settings/general',
              children: [
                {
                  path: '/notifications/settings/general',
                  name: '/notifications/settings/general',
                  component: () => import('@/components/pages/notifications/settings/general.vue'),
                },
                {
                  path: '/notifications/settings/advanced',
                  name: '/notifications/settings/advanced',
                  component: () => import('@/components/pages/notifications/settings/advanced/index.vue'),
                },
              ],
            },

            // Triggers
            {
              path: '/notifications/triggers',
              name: '/notifications/triggers',
              redirect: '/notifications/triggers/maintenance',
            },
            {
              path: '/notifications/triggers/maintenance',
              name: '/notifications/triggers/maintenance',
              component: () => import('@/components/pages/notifications/triggers/maintenance/index.vue'),
            },
          ],
        },

        // Users
        {
          path: '/users',
          name: '/users',
          redirect: '/users/overview',
        },
        {
          path: '/users/overview',
          name: '/users/overview',
          component: () => import('@/components/pages/users/overview.vue'),
        },
        {
          path: '/users/create',
          name: '/users/create',
          component: () => import('@/components/pages/users/create.vue'),
        },

        // Companies
        {
          path: '/companies',
          name: '/companies',
          redirect: '/companies/overview',
        },
        {
          path: '/companies/overview',
          name: '/companies/overview',
          component: () => import('@/components/pages/companies/overview.vue'),
        },
        {
          path: '/companies/create',
          name: '/companies/create',
          component: () => import('@/components/pages/companies/create.vue'),
        },

        // Settings
        {
          path: '/settings',
          name: '/settings',
          redirect: '/settings/company',
        },

        // Settings - company
        {
          path: '/settings/company',
          name: '/settings/company',
          component: () => import('@/components/pages/settings/company/index.vue'),
          redirect: '/settings/company/information',
          children: [{
            path: '/settings/company/information',
            name: '/settings/company/information',
            component: () => import('@/components/pages/settings/company/information.vue'),
          }, {
            path: '/settings/company/options',
            name: '/settings/company/options',
            component: () => import('@/components/pages/settings/company/options.vue'),
          }, {
            path: '/settings/company/maintenance',
            name: '/settings/company/maintenance',
            component: () => import('@/components/pages/settings/company/maintenance.vue'),
          }],
        },

        // Settings - Personal
        {
          path: '/settings/personal',
          name: '/settings/personal',
          redirect: '/settings/personal/information',
          component: () => import('@/components/pages/settings/personal/index.vue'),
          children: [{
            path: '/settings/personal/information',
            name: '/settings/personal/information',
            component: () => import('@/components/pages/settings/personal/information.vue'),
          }, {
            path: '/settings/personal/authentication',
            name: '/settings/personal/authentication',
            component: () => import('@/components/pages/settings/personal/authentication.vue'),
          }, {
            path: '/settings/personal/notifications',
            name: '/settings/personal/notifications',
            component: () => import('@/components/pages/settings/personal/notifications.vue'),
          }, {
            path: '/settings/personal/tokens',
            name: '/settings/personal/tokens',
            component: () => import('@/components/pages/settings/personal/tokens.vue'),
          }],
        },

        // Admin
        {
          path: '/admin',
          name: '/admin',
          redirect: '/admin/modules',
          component: () => import('@/components/pages/admin/index.vue'),
          children: [
            {
              path: '/admin/updates',
              name: '/admin/updates',
              redirect: '/admin/updates/types',
            },
            {
              path: '/admin/updates/types',
              name: '/admin/updates/types',
              component: () => import('@/components/pages/admin/updates/index.vue'),
              redirect: '/admin/updates/types/P10241',
              children: [{
                path: '/admin/updates/types/:type',
                name: '/admin/updates/types/:type',
                component: () => import('@/components/pages/admin/updates/type/index.vue'),
              }],
            },

            // Modules
            {
              path: '/admin/modules',
              name: '/admin/modules',
              redirect: '/admin/modules/overview',
            },
            {
              path: '/admin/modules/overview',
              name: '/admin/modules/overview',
              component: () => import('@/components/pages/admin/modules/overview.vue'),
            },

            {
              path: '/admin/modules/view/:guid',
              name: '/admin/modules/view/:guid',
              component: () => import('@/components/pages/admin/modules/view/index.vue'),
              redirect: '/admin/modules/view/:guid/ping-documents',
              children: [
                {
                  path: '/admin/modules/view/:guid/ping-documents',
                  name: '/admin/modules/view/:guid/ping-documents',
                  component: () => import('@/components/pages/admin/modules/view/pages/ping-documents.vue'),
                },
              ],
            },
          ],
        },
      ],
    },

    /*
    * begin::Authentication
    */
    {
      path: '/',
      component: () => import('@/components/standalone/index.vue'),
      beforeEnter: (to, from, next) => {
        // Check if authenticated
        // Because else, we're going to forward to /
        if (core.auth.authenticated === true && to.meta.authentication === false) {
          return next({
            path: '/',
            replace: true,
          });
        }

        return next();
      },
      meta: {
        authentication: false,
        standalone: true,
      },
      children: [
        {
          path: '/login',
          name: '/login',
          component: () => import('@/components/standalone/login.vue'),
          meta: {
            authentication: false,
            standalone: true,
          },
        },
        {
          path: '/sign-up',
          name: '/sign-up',
          component: () => import('@/components/standalone/sign-up.vue'),
          meta: {
            authentication: false,
            standalone: true,
          },
        },

        {
          path: '/forgot-password',
          name: '/forgot-password',
          component: () => import('@/components/standalone/forgot-password.vue'),
          meta: {
            authentication: false,
            standalone: true,
          },
        },
        {
          path: '/reset-password',
          name: '/reset-password',
          component: () => import('@/components/standalone/reset-password.vue'),
          meta: {
            authentication: false,
            standalone: true,
          },
        },

        // Company invite
        {
          path: '/invite',
          name: '/invite',
          component: () => import('@/components/standalone/invite.vue'),
          meta: {
            authentication: null,
            standalone: true,
          },
        },

        // Internal routes
        {
          path: '/internal/link',
          name: '/internal/link',
          component: () => import('@/components/standalone/internal/link.vue'),
          meta: {
            authentication: null,
            standalone: true,
          },
        },
      ],
    },

    // This endpoint will clear all authentication data
    {
      path: '/clear-data',
      name: '/clear-data',
      redirect () {
        core.auth.logout();
        return '/login';
      },
      meta: {
        authentication: null,
        standalone: true,
      },
    },
    /*
    * end::Authentication
    */

    /*
    *   begin::Internal routes
    */
    {
      path: '/internal/serial/:serial?',
      name: '/internal/serial/:serial?',
      component: () => import('@/components/standalone/internal/serial.vue'),
      meta: {
        authentication: null,
        standalone: true,
      },
    },
    /*
    *   end::Internal routes
    */

    /*
     *  begin::Redirects
     */
    // Support for legacy MyLuxura QR-code
    {
      path: '/signup/:serial/:uid?/:ssid?',
      redirect: (to) => ({
        path: '/internal/link',
        query: {
          id: (to.params.serial || undefined),
        },
      }),
      meta: {
        authentication: null,
        standalone: true,
      },
    },
    /*
     *  end::Redirects
     */

    /*
    *   begin::Errors
    */
    {
      // the 404 route, when none of the above matches
      path: '/404',
      name: '404',
      alias: '*',
      component: () => {
        // Show 404 error based on authentication or not
        if (core.auth.authenticated === true) {
          return import('@/components/layout/index.vue');
        }
        return import('@/components/standalone/index.vue');
      },
      meta: {
        standalone: true,
        resource: '*',
        authentication: null,

        error: '404',
      },
    },
    /*
    *   end::Errors
    */
  ],
});

export const isRouteAuthenticated = (route = null) => routerLib.isRouteAuthenticated(route);
export const hasAccessToRoute = (route = null) => routerLib.hasAccessToAuthenticatedRoute(route);

router.beforeEach(async (to, from, next) => {
  // By default, every page needs authentication
  // Only if 'meta.authentication: false' is set, it doesn't need authentication
  if (core.auth.authenticated === false && isRouteAuthenticated(to) === true) {
    return next({
      path: '/login',
      replace: true,
    });
  }

  // Check if route is authenticated, and if so, if resource has access to route
  if (core.auth.authenticated === true && isRouteAuthenticated(to) === true && hasAccessToRoute(to) === false) {
    // TODO: What happens if you don't have access to this page?
    // eslint-disable-next-line no-param-reassign
    to.meta.error = '404';
  }

  return next();
});

export default router;
