export default {
  types: {
    P10241: {
      title: 'P10241',
      description: 'Luxura Jewel, Vegaz, X5, X7, V6, V8',

      files: {
        display_version: 'Controller',
        display_touch_version_v1: 'Touch controller (v1)',
        display_touch_version_v2: 'Touch controller (v2)',
        display_files: 'Controller files (unzipped)',
        IPcII: 'IPcII',
        UV_LED: 'UV LED',
        AUDIO_BOARD: 'Audio board',
        POWERBOARD_MAIN: 'Powerboard Main',
        POWERBOARD_SENS: 'Powerboard Sens',
        DRIVER_1: 'Driver 1',
      },
    },
  },

  states: {
    published: {
      title: 'Published',
    },
    beta: {
      title: 'Beta',
    },
    revoked: {
      title: 'Revoked',
    },
    draft: {
      title: 'Draft',
    },
  },
};
