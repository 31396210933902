import i18n from '../index';

export default {
  fallback: {
    title: '{error}',
    message: '{message}',
  },

  // Validation errors (FE validation)
  ValidationError: {
    // Validation failed
    ValidationError: {
      title: 'Validation failed',
      message: 'Form is invalid.',
    },
  },

  // API Errors
  ApiError: {
    EndpointNotFound: {
      title: 'Endpoint not found',
      message: 'The requested endpoint is not found.',
    },
    NotFoundError: {
      title: 'Resource not found',
      message: 'The requested resource is not found.',
    },

    // Invalid user credentials while logging in
    InvalidUserCredentials: {
      title: 'Invalid credentials',
      message: 'Your email and/or password was incorrect.',
    },
    invalidPassword: {
      title: 'Invalid password',
      message: 'Current password does not match.',
    },

    // Failed validating
    failedValidators: {
      title: 'Validation failed',
      message: 'Something went wrong.',
    },

    // Device connect error
    deviceCombinationInvalid: {
      title: 'Module not found',
      message: 'The combination of the module ID and verification PIN is not found.',
    },

    // Invite invalid
    InvalidInvite: {
      title: 'Invalid invite',
      message: 'This invite does not exist or does not belong to you.',
    },

    // Company not found
    companyNotFound: {
      title: 'Company not found',
      message: 'The selected company cannot be found or is not linked to your account',
    },
    userAlreadyInCompany: {
      title: 'User already in company',
      message: 'The user already has access to this company.',
    },

    // Updates
    MissingFiles: {
      title: 'Missing files',
      message: (ctx) => {
        let translation = 'Not all required files are found in the uploaded file.';

        // Add files which are missing
        const details = ctx.named('details');
        if (details && details.files) {
          translation = `\
            ${translation}<br /><br /> \
            \
            Missing files:<br /> \
            ${details.files.map((file) => i18n.tu(`updates.types.${details.type}.files.${file}`) || file).map((file) => `- ${file}`).join('<br />')}\
          `;
        }

        return translation;
      },
    },
  },
};
