export default {
  // Errors
  errors: require('./errors/en.i18n').default, // eslint-disable-line

  // Notifications
  notifications: require('./notifications/en.i18n').default, // eslint-disable-line

  sunbeds: require('./sunbeds/en.i18n').default, // eslint-disable-line
  luxura_logs: require('./luxura_logs/en.i18n').default, // eslint-disable-line
  updates: require('./updates/en.i18n.js').default, // eslint-disable-line

  plural: {
    seconds: 'second | seconds',
    minutes: 'minute | minutes',
    hours: 'hour | hours',
    days: 'day | days',
    sunbeds: 'sunbed | sunbeds',
    sessions: 'session | sessions',
  },

  // Default window translations
  window: {
    beforeunload: 'You didn\'t submit your changes. Are you sure you want to leave?',
  },

  // Permission groups
  permission_groups: {
    type: {
      // VeDicium
      vedicium: {
        sysadmin: {
          name: 'System administrator',
          description: 'Super administrator with even more dangerous permissions.',
        },
        admin: {
          name: 'Super admin',
          description: 'Super administrator, consisting of all permissions including making dangerous and critical changes.',
        },
      },

      manufacturer: {
        admin: {
          name: 'Admin',
          description: 'Capable of viewing and changing all settings and insights.',
        },
        support: {
          name: 'Support',
          description: 'Capable of obtaining all information and settings required to provide support and service.',
        },
        employee: {
          name: 'Employee',
          description: 'Capable of obtaining all information required to create companies and perform general- and sales tasks.',
        },
        production: {
          name: 'Production',
          description: 'Capable of creating sunbeds and obtain all information and insights required to perform production- and QC tasks.',
        },
      },
      'manufacturer-us': {
        admin: {
          name: 'Admin',
          description: 'Capable of viewing all insights and changing all settings this company type can access.',
        },
        support: {
          name: 'Support',
          description: 'Support and aftersales level.',
        },
        employee: {
          name: 'Employee',
          description: 'Capable of obtaining all information required to create companies and perform general- and sales tasks.',
        },
      },

      distributor: {
        admin: {
          name: 'Admin',
          description: 'Capable of viewing all insights and changing all settings this company type can access.',
        },
        employee: {
          name: 'Employee',
          description: 'Capable of obtaining all information required to create companies and perform general- and sales tasks.',
        },
        service: {
          name: 'Service',
          description: 'Capable of obtaining all information and settings required to provide support and service.',
        },
      },

      finance: {
        admin: {
          name: 'Admin',
          description: 'Capable of viewing all insights and changing all settings this company type can access.',
        },
      },

      owner: {
        admin: {
          name: 'Admin',
          description: 'Capable of viewing all insights, change settings regarding their sunbed(s) and change company settings.',
        },
        employee: {
          name: 'Employee',
          description: 'Capable of obtaining all information required to perform general tasks regarding their sunbed(s).',
        },
        service: {
          name: 'Service',
          description: 'Capable of obtaining all information and settings required to provide service to their sunbed(s).',
        },
      },
      'sub-owner': {
        admin: {
          name: 'Admin',
          description: 'Capable of viewing all insights, change settings regarding their sunbed(s) and change company settings.',
        },
        employee: {
          name: 'Employee',
          description: 'Capable of viewing all insights and change settings regarding their sunbed(s).',
        },
        service: {
          name: 'Service',
          description: 'Capable of obtaining all information and settings required to provide service to their sunbed(s).',
        },
      },
    },
  },

  // Company types
  companies: {
    type: {
      vedicium: {
        name: 'VeDicium',
      },

      manufacturer: {
        name: 'Manufacturer',
        description: 'Company responsible for manufacturing, sales and aftersales of the sunbed.',
      },
      'manufacturer-us': {
        name: 'Manufacturer US',
        description: 'Company responsible for manufacturing, sales and aftersales of the sunbed in the United States.',
      },

      distributor: {
        name: 'Distributor',
        description: 'Company distributing the sunbed to owners. Distributors can access maintenance- and health information of sunbeds.',
      },

      finance: {
        name: 'Finance',
        description: 'Company financing sunbed to their client while legally owning the sunbed. Capable of managing sunbed.',
      },

      owner: {
        name: 'Owner',
        description: 'Company that owns the sunbed, capable of viewing al insights of sunbed and making changes.',
      },
      'sub-owner': {
        name: 'Sub-owner',
        description: 'Sub-party of owner, useful for grouping sunbeds.',
      },
    },
  },
};
